import React from 'react';
import './App.css';
import Adsense from './components/adsense';
import Navbar from  './components/navbar/navbar';
import Servicios from './components/servicios/servicios';

function App() {
  return (
    <div className="App">
      <div className="page">
          <Navbar />
          <Servicios />
          <Adsense/>
          {/* Aquí podrás añadir más componentes conforme sean necesarios */}
      </div>
     
      
    </div>
  );
}

export default App;

