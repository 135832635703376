import React, { useEffect } from 'react';

function Adsense() {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);
    
    return (
        <div>
            <ins className="adsbygoogle"
                style={{ display: 'inline-block', width: '728px', height: '90px' }}
                data-ad-client="ca-pub-5699530420497532"
                data-ad-slot="6741403983">
            </ins>
        </div>
    );
}

export default Adsense;
