import './navbar.css';

function Navbar() {
    return (
        <nav>
            <div>
                <img className="Logo" src="/Isologo.png" alt="Isologo" />
                <span>Empredelia</span>
            </div>
            <div>
                <a href="#servicios">Servicios</a>
                <a href="#empresa">Empresa</a>
                <a href="#sobre-nosotros">Sobre Nosotros</a>
            </div>
        </nav>
    );
}
export default Navbar;