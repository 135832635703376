import './servicios.css';
import CustomButton from './CustomButton';

function Servicios() {
    return (
        <div className="services">
            <CustomButton backgroundImg='/econodelia.png' url='http://econodelia.empredelia.com/'/>
            {/* Agrega más botones de servicio aquí conforme vayan siendo necesarios */}
        </div>
    );
}
export default Servicios;