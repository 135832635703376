import React from 'react';
import './CustomButton.css';

function CustomButton({ backgroundImg, url }) {

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div onClick={handleClick} className="custom-button">
      <div
        className="custom-button-background"
        style={{ backgroundImage: `url(${backgroundImg})` }}
      ></div>
    </div>
  );
}

export default CustomButton;
